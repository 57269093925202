const themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
const themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

// Change the icons inside the button based on previous settings
if (
  (localStorage.getItem('color-theme') === 'dark' ||
    (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches))
) {
  if (themeToggleLightIcon) {
    themeToggleLightIcon.classList.remove('hidden');
  }
} else {
  if (themeToggleDarkIcon) {
    themeToggleDarkIcon.classList.remove('hidden');
  }
}

const themeToggleBtn = document.getElementById('theme-toggle');

if (themeToggleBtn) {
  let event = new Event('dark-mode');

  themeToggleBtn.addEventListener('click', function () {
    // Toggle icons
    if (themeToggleDarkIcon) {
      themeToggleDarkIcon.classList.toggle('hidden');
    }
    if (themeToggleLightIcon) {
      themeToggleLightIcon.classList.toggle('hidden');
    }

    // If set via local storage previously
    if (localStorage.getItem('color-theme')) {
      if (localStorage.getItem('color-theme') === 'light') {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      } else {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      }
    } else {
      // If NOT set via local storage previously
      if (document.documentElement.classList.contains('dark')) {
        document.documentElement.classList.remove('dark');
        localStorage.setItem('color-theme', 'light');
      } else {
        document.documentElement.classList.add('dark');
        localStorage.setItem('color-theme', 'dark');
      }
    }

    document.dispatchEvent(event);
  });
}
